@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.App {
	text-align: center;
	position: relative;
}

.stack {
	display: flex;
	flex-direction: column;
	padding: 0;
	margin: 0;
}

.section {
	padding: 24px;
}

.profile {
	display: flex;
	flex-direction: row;
	gap: 16px;
	align-items: center;
}

.profile-text {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.profile-avatar {
	width: 65px;
	height: 65px;
}

.about {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.about-content {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.about-cta {
	align-self: flex-end;
}

.price {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.price-header {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.price-range {
	align-self: center;
}
